import React from 'react'
import BodyQuestion from './BodyQuestion'
import { Card, Alert } from 'antd';

import {
  MultipleChoicePreview,
  SimpleChoicePreview,
  ExtendedPreview,
  ClozeTextQuestionView,
  MatchTableQuestionView,
  PreviewArrangeWordsQuestion,
  PreviewArrangeSOP,
  PreviewMatchingQuestion,
  PreviewChooseCorrectWordsQuestion
} from './Types'

const arrayType = [1, 2, 3, 4, 5, 6, 7, 12, 13, 14, 15];
const QuestionDetail = (props) => {

  const { hideChoices, item, itemNumber, itemBody, choices, hideResponse } = props

  return (
    <div>

      {!hideChoices && (item.type == 1 || item.type == 7) && (
          <>
            <BodyQuestion item={item} itemNumber={itemNumber} itemBody={itemBody} />

            <SimpleChoicePreview
              response={item}
              choicesObj={choices}
              hideResponse={hideResponse}
            />
          </>
          
        )}
        {!hideChoices && item.type == 2 && (
          <>
            <BodyQuestion item={item} itemNumber={itemNumber} itemBody={itemBody} />
            <MultipleChoicePreview
              response={item}
              choicesObj={choices}
              hideResponse={hideResponse}
            />
          </>
        )}

        {!hideChoices && item.type == 3 && (
          <>
            <BodyQuestion item={item} itemNumber={itemNumber} itemBody={itemBody} />
            <ExtendedPreview
              response={item}
              choicesObj={choices}
              hideResponse={hideResponse}
            />
          </>
          
        )}
        {!hideChoices && (item.type == 4 || item.type == 15) && (
          <>
            <ClozeTextQuestionView
              // response={item}
              choicesObj={choices}
              hideResponse={hideResponse}
              item={item}
              itemNumber={itemNumber}
              itemBody={itemBody}
            />
          </>
          
        )}
        {!hideChoices && (item.type == 5) && (
          <>
            <BodyQuestion item={item} itemNumber={itemNumber} itemBody={itemBody} />
            <PreviewArrangeWordsQuestion
              // response={item}
              choicesObj={choices}
              hideResponse={hideResponse}
              item={item}
              itemNumber={itemNumber}
              itemBody={itemBody}
            />
          </>
          
        )}
        {!hideChoices && (item.type == 6) && (
          <>
            <BodyQuestion item={item} itemNumber={itemNumber} itemBody={itemBody} />
            <PreviewMatchingQuestion
              // response={item}
              choicesObj={choices}
              hideResponse={hideResponse}
              item={item}
              itemNumber={itemNumber}
              itemBody={itemBody}
            />
          </>
          
        )}
        {!hideChoices && item.type == 12 && (
          <>
            <BodyQuestion item={item} itemNumber={itemNumber} itemBody={itemBody} />
            <PreviewArrangeSOP
              item={item}
              itemNumber={itemNumber}
              itemBody={itemBody}
            />
          </>
          
        )}

        {!hideChoices && item.type == 14 && (
          <>
            <BodyQuestion item={item} itemNumber={itemNumber} itemBody={itemBody} />
            <MatchTableQuestionView
              item={item}
              itemNumber={itemNumber}
              itemBody={itemBody}
            />
          </>
          
        )}

        {!hideChoices && item.type == 13 && (
          <>
            <PreviewChooseCorrectWordsQuestion
              item={item}
              itemNumber={itemNumber}
              itemBody={itemBody}
            />
          </>
          
        )}
        {!hideChoices && arrayType.indexOf(item.type) === -1 && (
          <>
            <BodyQuestion item={item} itemNumber={itemNumber} itemBody={itemBody} />
            <Alert
              message="Câu hỏi này chưa được hỗ trợ hiển thị đầy đủ."
              type="warning"
              showIcon
            />
          </>
        
      )}
    </div>
  )
}

export default QuestionDetail