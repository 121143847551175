import React, { useEffect, useState } from 'react';
import { Row, Col, Space } from 'antd';
import { getChoiceLabel } from 'app/utils/functions'
import { ArrowRightOutlined } from '@ant-design/icons';
import { isEmpty } from 'lodash';
export default function PreviewMatchingQuestion(props) {

  const { item } = props;
  const [choicesHtml, setChoicesHtml] = useState(null);
  const [optionsHtml, setOptionsHtml] = useState(null);
  const [statusAnswer, setStatusAnswer] = useState(false);
  useEffect(()=>{
    if(!item) {
      return
    }
    let answers = item.answer ? JSON.parse(item.answer) : null;
    let questionChoices = item.choices ? JSON.parse(item.choices) : null;
    let dataResponse = item.data_response ? JSON.parse(item.data_response) : null;
    let choices = questionChoices[0];
    let options = questionChoices[1];
    let arrayChoices = [];

    for (const [key, value] of Object.entries(choices)) {
      let answer = null;
      arrayChoices.push({
        choice: {
          index: Number(key),
          content: value
        },
        answer : answer
      })
    }
    if(answers) {
      for (const [index, element] of answers.entries()) {
        let content = options[element]
        if(content){
          arrayChoices[index].answer = {
            index: element,
            content: content,
            status: dataResponse[index] == element
          }
        }
      }
      options = Object.fromEntries(Object.entries(options).filter(([key, value]) => {
        return !answers.includes(Number(key))
      }))
    }
    setOptionsHtml(isEmpty(options) ? null : options);
    setStatusAnswer(item?.status_answer ?? false);
    setChoicesHtml(arrayChoices);
  },[item])

  return (
    <div>
      {choicesHtml && Object.values(choicesHtml).map((e, index) => {

        return (
          <Row key={index + 'asop_row_' + item.id }>
            <Col flex="48%" flex-xl="49%" className="asop__preview__source">
              <div className="asop__preview_item">
                <div className="asop__preview_item_index">
                  <b>{index + 1}</b>
                </div>
                <div
                  className="asop__preview_item_body"
                  id={'_item_' + item.id + '_choice_' + index}
                  dangerouslySetInnerHTML={{
                    __html: e.choice.content || 'Chờ nhập dữ liệu',
                  }}
                />
              </div>
            </Col>
            <Col flex="2%">
              <ArrowRightOutlined className="select-answers-divider-arrow-right" />
            </Col>
            <Col key={index + 'asop_col_answer' + item.id} flex="48%" flex-xl="49%" className="asop__preview__source">
              { e.answer ? (
                <div className={`${e.answer?.status ? 'correct' : 'wrong'} asop__preview_item`}>
                  <div className="asop__preview_item_index">
                  <b>{getChoiceLabel()[e.answer.index]}</b>
                  </div>
                  <div
                    className="asop__preview_item_body"
                    id={'_item_' + item.id + '_choice_' + index}
                    dangerouslySetInnerHTML={{
                      __html: e.answer.content || 'Chờ nhập dữ liệu',
                    }}
                  />
                </div>
              ) : (
                <div className="skip asop__preview_item">
                  <div className="asop__preview_item_index">
                  {/* <b>{getChoiceLabel()[e.answer.index]}</b> */}
                  </div>
                  <div
                    className="asop__preview_item_body"
                    id={'_item_' + item.id + '_choice_' + index}
                    
                  />
                </div>
              )}
              
            </Col>
          </Row>
        )}
      )}
      
      {optionsHtml && (
        <>
          <Space className="mt-4 mb-2">
            Lựa chọn:
          </Space>
          <Row key={'index_asop_row' + item.id } className="matching-preview-answers-items">
          
            {Object.values(optionsHtml).map((e, index) => {

              return (
                <>
                    <Col key={'asop_col' + item.id + index} flex="48%" className="asop__preview__source">
                      <div className="asop__preview_item">
                        <div className="asop__preview_item_index">
                          <b>{getChoiceLabel()[index + 1]}</b>
                        </div>
                        <div
                          className="asop__preview_item_body"
                          id={'_item_' + item.id + '_choice_' + index}
                          dangerouslySetInnerHTML={{
                            __html: e || 'Chờ nhập dữ liệu',
                          }}
                        />
                      </div>
                    </Col>
                  
                </> 
              )}
            )}
          </Row>
        </>
        
      )}
    </div>
  );
}


export { PreviewMatchingQuestion };
