import React, { useEffect, useState } from 'react';
import { Row, Col, Space } from 'antd';
import { getChoiceLabel } from 'app/utils/functions'
export default function PreviewArrangeSOP(props) {

  const { item } = props;
  const [choicesHtml, setChoicesHtml] = useState(null);
  const [statusAnswer, setStatusAnswer] = useState(false);
  useEffect(()=>{
    if(!item) {
      return
    }
    let answers = item.answer ? JSON.parse(item.answer) : null;
    let choices = item.choices ? JSON.parse(item.choices) : null;
    let arrayChoices = [];

    setStatusAnswer(item?.status_answer ?? false);

    for (const [key, value] of Object.entries(choices)) {
      let answer = null;
     
      arrayChoices.push({
        choice: {
          index: Number(key),
          content: value
        },
        answer : answer
      })
    }

    if(answers) {
      for (const [index, element] of answers.entries()) {
        let content = choices[element]
        if(content){
          arrayChoices[index].answer = {
            index: element,
            content: content
          }
        }
        
        
      }
    }
    setChoicesHtml(arrayChoices);
  },[item])

  return (
    <>
      {!choicesHtml ? '' : Object.values(choicesHtml).map((e, index) => {
        // return (
        //   <div key={e + index} className="" dangerouslySetInnerHTML={{ __html: e }}></div>
        // );

        return (
          <Row key={'asop_'+item.id+index}>
            <Col flex="49%" className="asop__preview__source">
              <div className="asop__preview_item">
                <div className="asop__preview_item_index">
                  <b>{getChoiceLabel()[e.choice.index]}</b>
                </div>
                <div
                  className="asop__preview_item_body"
                  id={'_item_' + item.id + '_choice_' + index}
                  dangerouslySetInnerHTML={{
                    __html: e.choice.content || 'Chờ nhập dữ liệu',
                  }}
                />
              </div>
            </Col>
            <Col flex="2%"/>
            <Col flex="49%" className="asop__preview__source">
              { e.answer ? (
                <div className={`${statusAnswer ? 'correct' : 'wrong'} asop__preview_item`}>
                  <div className="asop__preview_item_index">
                  <b>{getChoiceLabel()[e.answer.index]}</b>
                  </div>
                  <div
                    className="asop__preview_item_body"
                    id={'_item_' + item.id + '_choice_' + index}
                    dangerouslySetInnerHTML={{
                      __html: e.answer.content || 'Chờ nhập dữ liệu',
                    }}
                  />
                </div>
              ) : (
                <div className="skip asop__preview_item">
                  <div className="asop__preview_item_index">
                  {/* <b>{getChoiceLabel()[e.answer.index]}</b> */}
                  </div>
                  <div
                    className="asop__preview_item_body"
                    id={'_item_' + item.id + '_choice_' + index}
                    
                  />
                </div>
              )}
              
            </Col>
          </Row>
        )
      }
      )}
    </>
  );
}


export { PreviewArrangeSOP };
