import React, { useEffect, useState } from 'react';
import { getMatchClozeText, replaceDatasetValue } from 'app/utils/functions'
import 'material-design-icons/iconfont/material-icons.css';
import { isEmpty } from 'lodash'
export default function PreviewChooseCorrectWordsQuestion(props) {

  const { item, itemNumber, itemBody } = props;
  const [bodyHtml, setBodyHtml] = useState('');
  const [choicesHtml, setChoicesHtml] = useState(null);
  useEffect(()=>{
    if(!item) {
      return
    }
    let choices = item.choices ? JSON.parse(item.choices) : null;
    
    let body = structuredClone(item.body);
    body = replaceDatasetValue(body, item?.render_options)
    const awnserBody = getMatchClozeText(item.body)
    const awnser = item.answer ? JSON.parse(item.answer) : null;
    // const dataResponse = item.data_response ? JSON.parse(item.data_response) : null;
    let checkAnwser = item.status_answer ?? false;

    for (let index = 0; index < awnserBody.length; index++) {
      let fillAnwser = `[Bỏ qua]`;
      if( awnser && awnser[index]) {
        fillAnwser = `${choices[awnser[index]]?.choice || '[Bỏ qua]'}`;
        choices = Object.fromEntries(Object.entries(choices).filter(([key, value]) => !awnser[index].includes(Number(key))))
      }
      fillAnwser = `<span class='mx-2 ${checkAnwser ? 'correct' : 'wrong'} pccw-blank-item'> ${fillAnwser} </span>`
      body = body.replace(awnserBody[index], fillAnwser)
    }
    setBodyHtml(body)
    setChoicesHtml(isEmpty(choices) ? null : choices);
  },[item])

  return (
    <>
      <div className="avoid-break mb-2 flex">
        <b
          className={
          itemBody || item?.is_parent == 1
              ? ' mr-2'
              : ' item-num-empty mr-2'
          }
        >
          {itemNumber}.{' '}
        </b>
      </div>
      <div className="avoid-break mb-2 flex pccw-box-items">
        {
          !choicesHtml ? '' : Object.values(choicesHtml).map((e, index) => {
            
            return (
              <span
                key={'pccw'+item.id+index}
                className="pccw-box-item"
                dangerouslySetInnerHTML={{ __html: e.choice || '' }}
                id={`_item_${item?.id}_body`}
              />
            )
          })
        }
        
      </div>
      <div className="avoid-break mb-2 flex">
        <span
          dangerouslySetInnerHTML={{ __html: bodyHtml || '' }}
          id={`_item_${item?.id}_body`}
        />
      </div>
    </>
    
  );
}


export { PreviewChooseCorrectWordsQuestion };
